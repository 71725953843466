import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import noise from "simplenoise";
console.log(noise)

// const drawWave = () => {
//   let yoff = 0.0; // 2nd dimension of perlin noise
//   function setup() {
//     createCanvas(600, 600);
//   }
//   setup()

//   function draw() {
//     background(51);

//     stroke(255);
//     // We are going to draw a polygon out of the wave points
//     beginShape();

//     let xoff = 0; // Option #1: 2D Noise
//     // let xoff = yoff; // Option #2: 1D Noise

//     // Iterate over horizontal pixels
//     for (let x = 0; x <= width; x += 10) {
//       // Calculate a y value according to noise, map to

//       // Option #1: 2D Noise
//       let y = map(noise(xoff, yoff), 0, 1, 200, 300);

//       // Option #2: 1D Noise
//       // let y = map(noise(xoff), 0, 1, 200,300);

//       // Set the vertex
//       vertex(x, y);
//       // Increment x dimension for noise
//       xoff += 0.05;
//     }
//     // increment y dimension for noise
//     yoff += 0.01;
//     vertex(width, height);
//     vertex(0, height);
//     endShape(CLOSE);
//   }
// };
// drawWave()


// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
const lenis = new Lenis({
  duration: 0,
})
lenis.on('scroll', (e) => { })
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
const anchors = document.querySelectorAll(".js-anchor");
const header = document.querySelector('.header');
anchors.forEach((anchor) => {
  anchor.addEventListener("click", (e) => {
    e.preventDefault();
    const to = e.currentTarget.getAttribute('href')
    lenis.scrollTo(document.querySelector(to).offsetTop - header.offsetHeight - 40);
  });
})
const smartBreakPoint = 769;
const smartBreakPointMediaQuery = "(min-width: " + smartBreakPoint + "px)";
const isSmartPhone = () => {
  // デバイス幅が640px以下の場合にスマホと判定する
  if (window.matchMedia && window.matchMedia('(max-device-width: ' + (smartBreakPoint - 1) + 'px)').matches) {
    return true;
  } else {
    return false;
  }
}



let mm = gsap.matchMedia();
const initXScrollCard = () => {
  const card = gsap.utils.toArray('.js-x-scroll-item');
  const wrapper = document.querySelector('.js-x-scroll');
  if (card.length === 0) {
    return false;
  }
  const containerWidth = wrapper.offsetWidth;
  mm.add(smartBreakPointMediaQuery, () => {
    gsap.to(card, {
      xPercent: -80 * (card.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: wrapper,
        pin: true,
        scrub: 1,
        start: "top top",
        end: `+=${(containerWidth)}`,
        anticipatePin: 1,
        invalidateOnRefresh: true,
      },
    });

  });
}
initXScrollCard()
/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ノードリストを配列にする
  let returnText = ''; // 最終的に返すテキスト

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //テキストの場合
      const text = node.textContent.replace(/\r?\n/g, ''); //テキストから改行コード削除
      const splitText = text.split(''); // 一文字ずつ分割
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanタグで挟んで連結
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

const spanWrapTextElements = document.querySelectorAll('.js-spanwrap')
if (spanWrapTextElements) {
  // console.log(spanWrapTextElements)
  spanWrapTextElements.forEach((spanWrapTextElement) => {
    spanWrapTextElement.innerHTML = spanWrapText(spanWrapTextElement)
  });
}


let featureTitle = document.querySelector('.js-feature-title')
if (featureTitle) {
  featureTitle.innerHTML = spanWrapText(featureTitle)
}
gsap.utils.toArray('.js-enter-active').forEach((box, index) => {
  gsap.to(box, {
    scrollTrigger: {
      trigger: box,
      start: 'top bottom',
      onEnter: () => box.classList.add('is-active'),
    }
  });
});
const body = document.querySelector("body")
const onEnterToggleAction = (box) => {
  box.classList.add('is-active')
  body.classList.add('is-active')
}
const onLeaveToggleAction = (box) => {
  box.classList.remove('is-active')
  body.classList.remove('is-active')
}
gsap.utils.toArray('.js-enter-toggle-active').forEach((box, index) => {
  gsap.to(box, {
    scrollTrigger: {
      trigger: box,
      start: 'top center',
      end: 'bottom center',
      onEnter: () => onEnterToggleAction(box),
      onEnterBack: () => onEnterToggleAction(box),
      onLeave: () => onLeaveToggleAction(box),
      onLeaveBack: () => onLeaveToggleAction(box),
    }
  });
});
// gsap.fromTo(featureTitle, {
//   y: 20,
//   scale: 0
// }, {
//   y:0 ,
//   scale: 1,
//   duration: 1,
//   scrollTrigger: {
//     trigger: box,
//     start: 'top bottom',
//     end: 'bottom top',
//   }
// });

const scalein = gsap.utils.toArray('.js-scalein-effect1');
if (scalein.length != 0) {
  scalein.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      scale: 0
    }, {
      y: 0,
      scale: 1,
      duration: 1,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}

const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 2,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const stfadeInEls = gsap.utils.toArray('.js-stagger-fadein-effect1');
if (stfadeInEls.length != 0) {
  // stfadeInEls.forEach((box, index) => {
  gsap.fromTo(stfadeInEls, {
    y: 20,
    scale: .25,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    duration: .5,
    scale: 1,
    stagger: .25,
    scrollTrigger: {
      trigger: document.querySelector('.js-stagger-fadein-effect1'),
      start: 'top bottom',
      end: 'bottom top',
    }
  });
  // });
}



const count = 25;
const particleClass = "js-particle"
const particleClassList = ["js-particle type1", "js-particle type2", "js-particle type3"]
const particleSizes = ["4.8rem", "3.6rem", "2.4rem", "2rem"]
const containers = document.querySelectorAll('.js-particle-content');
let tempelem;

containers.forEach((container) => {
  const createCount = container.getAttribute('data-count') || count;
  const w = container.offsetWidth;
  const h = container.offsetHeight;
  for (let i = 0; i < createCount; i++) {
    tempelem = document.createElement('div');
    tempelem.className = particleClass
    container.appendChild(tempelem)
    let size = gsap.utils.random(particleSizes);
    gsap.set(tempelem, {
      x: gsap.utils.random(0, w),
      y: gsap.utils.random(0, (h)),
      // y: gsap.utils.random(0, (h * 0.7)),
      scale: gsap.utils.random(0, 1),
      className: gsap.utils.random(particleClassList),
      // opacity: gsap.utils.random(.1, 1),
      width: size,
      height: size,
      // backgroundColor: particleColors[colornum],
    })
    anime(tempelem)
  }
})

function anime(elm) {
  gsap.to(elm, gsap.utils.random(1, 5), {
    yPercent: gsap.utils.random(-200, 200),
    xPercent: gsap.utils.random(-200, 200),
    // x: gsap.utils.random(0, 1),
    scale: 1,
    ease: "none",
    repeat: -1,
    yoyo: 10,
    transition: .2,
    delay: -1,
  })
}

const moveGraph = () => {
  const graphs = document.querySelectorAll('.js-graph-wrap1')
  if (!graphs) {
    return false;
  }
  graphs.forEach((graph) => {
    const graphvalue = graph.getAttribute("data-percent");
    const fillcolor = graph.getAttribute("data-fillcolor");
    const rects = (graph.querySelectorAll('rect'))
    const rectsFill = rects.length - Number(rects.length * (1 - graphvalue / 100));
    const targetRects = [...rects].slice(0, Number(rectsFill));
    gsap.set(targetRects, {
      fill: fillcolor,
      stagger: .03,
      transition: .15,
      scrollTrigger: {
        trigger: graph,
        start: 'top bottom',
        end: 'bottom top',
      }
    })
  })
}
moveGraph()

const animationCircle = () => {
  const circles = document.querySelectorAll('.js-animation-circle')
  if (!circles) {
    return false;
  }
  circles.forEach((circle) => {
    const minx = circle.getAttribute("data-minx") || -25;
    const maxx = circle.getAttribute("data-maxx") || 50;
    // gsap.set(circle, {
    //   x: gsap.utils.random(0, w),
    //   y: gsap.utils.random(0, (h )),
    //   // y: gsap.utils.random(0, (h * 0.7)),
    //   scale: gsap.utils.random(0, 1),
    //   className: gsap.utils.random(particleClassList),
    //   // opacity: gsap.utils.random(.1, 1),
    //   width: size,
    //   height: size,
    //   // backgroundColor: particleColors[colornum],
    // })
    gsap.to(circle, gsap.utils.random(2, 5), {
      yPercent: gsap.utils.random(30, 70),
      xPercent: gsap.utils.random(minx, maxx),
      ease: "none",
      repeat: -1,
      yoyo: 1,
      transition: .5,
    })
  })

}
animationCircle()



const initNoise = () => {
  const canvas = document.querySelector('#js-move-line');
  if (!canvas) {
    return false;
  }


  let stageW = document.querySelector(".js-canvas-wrap").clientWidth; // 画面の幅
  let stageH = document.querySelector(".js-canvas-wrap").clientHeight; // 画面の高さ

  const context = canvas.getContext('2d');

  resize();
  window.addEventListener('resize', resize);
  tick();

  /** エンターフレームのタイミングです。 */
  function tick() {
    requestAnimationFrame(tick);
    const time = Date.now() / 4000;
    draw(time);
  }

  function draw(time) {
      // 画面をリセット
      context.clearRect(0, 0, stageW, stageH);
      context.lineWidth = .5;

      const amplitude = stageH / 1.6; // 振幅（縦幅)の大きさ
      const lineNum = 30; // ラインの数
      const segmentNum = 300; // 分割数

      [...new Array(lineNum).keys()].forEach(j => {
        const coefficient = 40 + j;

        context.beginPath();
          // 色を指定
        const fanwisegradient = context.createLinearGradient(0, 0, 0, 150);
        fanwisegradient.addColorStop(0, '#ffffff');
        fanwisegradient.addColorStop(0.33, '#fcbee8');
        fanwisegradient.addColorStop(0.62, '#fea4ed');
        fanwisegradient.addColorStop(1, '#c50000');

        context.strokeStyle = fanwisegradient;
        [...new Array(segmentNum).keys()].forEach(i => {

          const x = i / (segmentNum - 1) * stageW;
          const px = i / coefficient;
          const py = (j / 50 + time);
          const y = amplitude * noise.perlin2(px, py) + stageH / 2;

          if (i === 0) {
            context.moveTo(x, y);
          } else {
            context.lineTo(x, y);
          }
        });
        context.stroke();
      });
  }


  function resize() {
    canvas.width = document.querySelector(".js-canvas-wrap").clientWidth;
    canvas.height = document.querySelector(".js-canvas-wrap").clientHeight;
    // canvas.width = document.querySelector(".js-canvas-wrap").clientWidth  * devicePixelRatio;
    // canvas.height = document.querySelector(".js-canvas-wrap").clientHeight* devicePixelRatio;
  }
  resize()

}
initNoise()